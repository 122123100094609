import * as React from 'react';
import { store } from '../../../store';
import { CheckboxGroup } from '../../Search/SearchResults/Filter/CheckboxGroup';
import {
  searchRequestFundings,
  exportRequest,
  setIsExporting,
  setClearExporting,
} from '../../../app/Fundings/actions';
import { useSelector } from 'react-redux';
import { Spinner } from '@fluentui/react/lib/Spinner';
import { exportToExcel } from '../../../utils/excelExport';
import IconArrowRigh from '../../../assets/icon--arrow--right.svg';
import axios from 'axios';
import * as FormData from 'form-data';

const statuses = [
  { key: 'topic_status', value: '31094501', label: 'Forthcoming' },
  { key: 'topic_status', value: '31094502', label: 'Open' },
  { key: 'topic_status', value: '31094503', label: 'Closed' },
];

const excludedFields = [
  'reference',
  'workProgrammepart',
  'additionalInfos',
  'actions',
  'topicConditions',
  'type',
  'programmePeriod',
];

const renamedFields = {
  identifier: 'topicIdentifier',
  summary: 'topicName',
  descriptionByte: 'description',
  startDate: 'openingDate',
  budgetOverview: 'budget',
};

const fieldOrder = [
  'topicIdentifier',
  'topicName',
  'typesOfAction',
  'callIdentifer',
  'callTitle',
  'description',
  'deadlineDate',
  'deadlineModel',
  'budget',
  'keywords',
  'tags',
  'openingDate',
  'url',
];

const Filter: React.FC = () => {
  const [frameworkProgramme, setFrameworkProgramme] = React.useState<any[]>([]);
  const [facetFetching, setFacetFetching] = React.useState<boolean>(false);
  const [callProgramme, setCallProgramme] = React.useState<string[]>(['43108390']);
  const [callStatus, setCallStatus] = React.useState<string[]>(['31094501', '31094502']);
  const [label, setLabel] = React.useState<string>('');
  const exportData = useSelector((state: any) => state.funding.exportResults);
  const limit: number = 10;
  const offset: number = 0;
  const isExporting = useSelector((state: any) => state.funding.isExporting);
  const isloading = useSelector((state: any) => state.funding.loading);
  const EU_REDIRECT_URL: string = process.env.REACT_APP_EU_REDIRECT_URL || 'https://ec.europa.eu/info/funding-tenders/opportunities/portal/screen/opportunities/topic-details/'
  const isTrialUser = localStorage.getItem('isTrialUser') === "true";

  const handleCallProgrammeChange = (value: any) => {
    setCallProgramme((prev) => {
      const updatedProgramme = prev.includes(value) ? prev.filter((status) => status !== value) : [value];
      applyFilters(updatedProgramme, callStatus);
      return updatedProgramme;
    });
  };

  const handleCallStatusChange = (value: any) => {
    setCallStatus((prev) => {
      const updatedStatus = prev.includes(value)
        ? prev.filter((status) => status !== value)
        : [...prev, value];
      applyFilters(callProgramme, updatedStatus);
      return updatedStatus;
    });
  };

  const fetchFacet = async () => {
    try {
      const formData = new FormData();
      const queryText = JSON.stringify({
        bool: {
          must: [
            { terms: { type: ['1', '2', '8'] } },
            { terms: { status: ['31094501', '31094502', '31094503'] } },
          ],
        },
      });
      const langText = JSON.stringify(['en']);

      formData.append('query', new Blob([queryText], { type: 'application/json' }), 'query.json');
      formData.append('languages', new Blob([langText], { type: 'application/json' }), 'language.json');
      const facetUrl: string = process.env.REACT_APP_EU_FACET_URL || 'https://api.tech.ec.europa.eu/search-api/prod/rest/facet?apiKey=SEDIA&text=***';
      setFacetFetching(true);
      const { data } = await axios.post(facetUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const transformedFrameworkProgramme = data.facets[7].values.map((item: any) => ({
        key: 'framework_programme',
        value: item.rawValue,
        label: item.value,
      }));
      setFacetFetching(false);
      setFrameworkProgramme(transformedFrameworkProgramme);
    } catch (err) {
      console.error(`Error fetching facet:`, err);
    }
  };

  React.useEffect(() => {
    fetchFacet();
    applyFilters(callProgramme, callStatus);
  }, []);

  const handleExport = (updatedProgramme: string[], updatedStatus: string[]) => {
    const getLabels = frameworkProgramme
      .filter((programme) => updatedProgramme.includes(programme.value))
      .map((matchedProgramme) => matchedProgramme.label);
    setLabel(getLabels[0]);
    store.dispatch(setIsExporting(true));
    store.dispatch(
      exportRequest({
        frameworkProgramme: JSON.stringify(updatedProgramme),
        status: JSON.stringify(updatedStatus),
      }),
    );
  };

  const applyFilters = (updatedProgramme: string[], updatedStatus: string[]) => {
    store.dispatch(
      searchRequestFundings({
        frameworkProgramme: JSON.stringify(updatedProgramme),
        status: JSON.stringify(updatedStatus),
        limit: limit,
        offset: offset,
      }),
    );
  };

  React.useEffect(() => {
    if (exportData && exportData.length > 0) {
      const processData = exportData.map((item: any) =>
        Object.entries(item).reduce((acc: any, [key, value]) => {
          acc[key] = Array.isArray(value) ? JSON.stringify(value) : value;
          return acc;
        }, {}),
      );
      exportToExcel(processData, label, excludedFields, renamedFields, fieldOrder, EU_REDIRECT_URL);
      store.dispatch(setClearExporting());
      store.dispatch(setIsExporting(false));
    }
  }, [exportData]);

  const filterableItems = [
    {
      value: 'call_programme_description',
      name: 'Call programme',
      items: frameworkProgramme,
      onChange: handleCallProgrammeChange,
      selectedItem: callProgramme,
    },
    {
      value: 'call_status',
      name: 'Call status',
      items: statuses,
      onChange: handleCallStatusChange,
      selectedItem: callStatus,
    },
  ];

  const clearFilters = () => {
    setCallProgramme([]);
    setCallStatus([]);
  };

  return (
    <div
      className="result-filter__container"
      style={isExporting || isloading ? { opacity: 0.75, pointerEvents: 'none' } : {}}
    >
      <div className="result-filter">
        <p className="filter-group-label">Filter by</p>
        {!facetFetching ? (
          filterableItems.map((filterable: any) => (
            <CheckboxGroup
              key={`${filterable.name}_${filterable.value}`}
              label={filterable.name}
              name={filterable.name}
              onChange={filterable.onChange}
              options={filterable.items}
              value={filterable.selectedItem}
              hasShowMore={filterable.name === 'Call status' ? false : true}
            />
          ))
        ) : (
          <Spinner />
        )}
        {callProgramme.length > 0 || callStatus.length > 0 ? (
          <div className="result-filter__filter-item">
            <button className="result-filter__clear-button" onClick={clearFilters}>
              Reset filters
            </button>
          </div>
        ) : null}
        {(callProgramme.length > 0 || callStatus.length > 0) && !isTrialUser ? (
          <div className="result-filter__filter-item">
            <p className="filter-group-label" style={{ marginTop: '30px' }}>
              Export all
            </p>

            {!isExporting ? (
              <div className="export-all">
                <img src={IconArrowRigh} alt="next" className="export-icon" />
                <li onClick={() => handleExport(callProgramme, callStatus)} className="dropdown__item">
                  Export as excel (.xlsx)
                </li>
              </div>
            ) : (
              <Spinner />
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Filter;
