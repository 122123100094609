import * as React from 'react';
import '../../../components/Search/SearchResults/SearchResults.css';

interface IFundingResultsProps {
  data: any;
  index: number;
}

export const FundingResults: React.FC<IFundingResultsProps> = ({ index, data }) => {
  const isTrialUser = localStorage.getItem('isTrialUser') === "true";
  return (
    <div className="search-results--row">
      {!isTrialUser ? (
        <div className="search-results--first-column">
          <div className="search-results--result-name">{`${data?.callIdentifier[0]} ${data?.callTitle[0]}`}</div>
          <div className="search-results--result-description">{data?.summary}</div>
        </div>
      ) : (
        <div className="search-results--first-column">
          {isTrialUser && index < 3 ? (
            <div>
              <div className="search-results--result-name">{`${data?.callIdentifier[0]} ${data?.callTitle[0]}`}</div>
              <div className="search-results--result-description">{data?.summary}</div>
            </div>
          ) : (
            <div className="search-results--subscribe">
              <h1>
                {'To see result details and unlock share feature please '}
                <a className="" target="blank" href="https://spinbase.eu/pricing/">
                  {'upgrade your subscription'}
                </a>
              </h1>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
